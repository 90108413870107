// ***** custom color section start *****

// color varibles
$local-primary: #02425a;
$local-secondary: #277578;
$local-lightGreen: #7db6a6;
$local-lightBrown: #cabba8;
$local-darkBrown: #a4846b;
$local-borderGrey: #cbcbcb;
$local-white: #fff;
$local-black: #000;

// array of colors
$colors: "local-primary" #02425a, "local-secondary" #277578,
  "local-lightGreen" #7db6a6, "local-lightBrown" #cabba8,
  "local-darkBrown" #a4846b, "local-white" #fff, "local-black" #000,
  "local-borderGrey" #cbcbcb;

// text color classes
@each $color_name, $value in $colors {
  .text-#{$color_name} {
    color: $value !important;
  }
}

// bg color classes
@each $color_name, $value in $colors {
  .bg-#{$color_name} {
    background-color: $value !important;
  }
}

// ***** custom color section end *****

// ***** Mixins starts *****

// responsive mixin
@mixin mediaResponsive($breakpoint) {
  @if $breakpoint == "xxxxl" {
    @media (min-width: 1500px) {
      @content;
    }
  } @else if $breakpoint == "xxxl" {
    @media (min-width: 1366px) {
      @content;
    }
  } @else if $breakpoint == "xxl" {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $breakpoint == "xl" {
    @media (max-width: 1200px) {
      @content;
    }
  } @else if $breakpoint == "mLg" {
    @media only screen and (min-width: 961px) and (max-width: 1024px) {
      @content;
    }
  } @else if $breakpoint == "lg" {
    @media (max-width: 960px) {
      @content;
    }
  } @else if $breakpoint == "md" {
    @media (max-width: 767px) {
      @content;
    }
  } @else if $breakpoint == "sm" {
    @media (max-width: 575px) {
      @content;
    }
  } @else if $breakpoint == "xs" {
    @media (max-width: px) {
      @content;
    }
  }
}

@mixin text-center {
  text-align: center;
}

body {
  background-color: $local-white;
}
main {
  min-height: calc(100vh - 150px);
  margin-top: 71px;
}

body.home-page main {
  margin-top: 0;
}
// ***** Mixins starts *****

// ***** typography section starts *****

.cooper {
  font-family: "Cooper" !important;
}
.lexend {
  font-family: "Lexend";
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-medium {
  font-weight: 500 !important;
}

.font-weight-semi-bold {
  font-weight: 600 !important;
}

a,
button {
  color: $local-lightGreen;
  font-weight: 500;
  transition: all 0.3s ease;
}

body,
a,
p,
button,
h1,
h2,
h3,
h5,
h4,
h6,
p,
label,
input {
  font-family: "Lexend" !important;
  line-height: 1.5;
}
.text-center {
  @include text-center();
}
.MuiFormControl-root {
  label {
    color: $local-black !important;
  }
}

// ***** typography section end *****

// ***** form control starts *****

// react select
.basic-single {
  margin-top: 0 !important;
  top: -12px;
  .select {
    //select
    &__control {
      //select__control
      border: 0 !important;
      border-bottom: 1px solid $local-borderGrey !important;
      border-radius: 0 !important;
      &--is-focused {
        //select__control--is-focused
        border-bottom: 1px solid $local-lightGreen !important;
        box-shadow: none;
      }
    }
    &__placeholder {
      font-weight: 400;
    }
    &__value-container {
      padding: 2px 0px !important;
    }
    &__indicator-separator {
      display: none;
    }
    &__dropdown-indicator {
      svg {
        color: $local-black;
      }
    }
  }
}

.profile-select {
  .select {
    //select
    &__control {
      font-size: 13px;
      color: #000;
    }
  }
}

.border-0 {
  @extend .basic-single;
  top: 0;
  margin: inherit;
  .select {
    //select
    &__control {
      background-color: transparent;
      font-weight: 400;
      border-bottom: 0px !important;
    }
  }
}

.dropDownplaceholder {
  .select__single-value {
    color: #000 !important;
  }
  border-bottom: 1px solid #cbcbcb;
  font-family: "Lexend" !important;
  .select__placeholder {
    color: #000;
    font-weight: 400;
    font-size: 16px;
  }
}

.specificDateSelect {
  width: auto !important;
}

.datepickerPlaceHolder {
  border-bottom: 1px solid #cbcbcb;
  input {
    background-image: none !important;
    font-family: "Lexend" !important;
    &::placeholder {
      color: #000;
      font-size: 16px;
      font-weight: 400;
    }
  }
}

.donation-date-picker {
  border-bottom: 1px solid #277578;
  input {
    background-image: none !important;
    font-family: "Lexend" !important;
    &::placeholder {
      color: #fff;
      font-size: 16px;
      font-weight: 400;
    }
  }
  &::after {
    content: "\e910";
    font-family: "icomoon" !important;
    position: absolute;
    right: 8px;
    top: 5px;
    font-size: 18px;
    color: $local-white;
  }
}

.organizationTopFilterDropdown {
  @extend .border-0;
  margin: 0;
  width: 100%;
  .select {
    //select
    &__control {
      div {
        color: #fff !important;
      }
      svg {
        color: #fff !important;
      }
      border-bottom: 1px solid #277578 !important;
    }
  }
}

.attendGustDropownStyle {
  @extend .border-0;
  margin: 0;
  width: 100%;
  .select {
    //select
    &__control {
      div {
        color: #000 !important;
      }
      border-bottom: 1px solid #277578 !important;
    }
  }
}

.organizationInputStyle {
  color: white;
  input {
    border-color: white;
  }
}

.form-group {
  position: relative;
  margin-bottom: 2rem;
  input,
  textarea,
  .form-control {
    width: 100%;
    border: 0;
    line-height: 1.5;
    border-bottom: 1px solid $local-borderGrey;
    border-radius: 0;
    transition: all 0.3s ease;
    &:focus {
      border-bottom-color: $local-lightGreen;
    }
    &:placeholder-shown {
      & + .form-control-placeholder {
        top: 0px;
        left: 0;
        color: rgba($color: $black, $alpha: 0.5);
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 0;
        span.text-local-primary {
          position: absolute;
          top: -3px;
          left: auto;
        }
      }
    }
    & + .form-control-placeholder,
    &:focus + .form-control-placeholder {
      position: absolute !important;
      transition: all 0.2s linear;
      -webkit-transition: all 0.2s linear;
      -moz-transition: all 0.2s linear;
      -o-transition: all 0.2s linear;
      top: -20px;
      left: 0;
      font-size: 12px;
      margin-left: 0;
      color: $black;
      font-weight: 400;
      width: initial;
      padding-right: 10px;
      max-width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      span.text-local-primary {
        position: absolute;
        top: -3px;
        left: auto;
      }
    }
  }
}
span.error-text {
  font-size: 0.75rem;
  color: red;
}

.MuiInput-underline {
  &.Mui-focused &::after {
    transform: scaleX(1) !important;
    border-bottom: 1px solid $local-lightGreen !important;
  }
}

// ***** form control starts *****

// nav logo
.MuiButton-label {
  img {
    max-width: 180px;
  }
}

// login
// .login-container {
//   // margin-top: 80px !important;
// }
.login-card {
  padding: 10px;
  @include mediaResponsive(md) {
    padding: 40px;
  }
  @include mediaResponsive(sm) {
    padding: 20px;
  }
  &-body {
    @include mediaResponsive(sm) {
      padding: 0 !important;
    }
  }
}

.sign-up-card {
  padding: 50px;
  @include mediaResponsive(md) {
    padding: 40px;
  }
  @include mediaResponsive(sm) {
    padding: 20px;
  }
  &-body {
    @include mediaResponsive(sm) {
      padding: 0 !important;
    }
  }
}

// general

.d-none {
  display: none;
}

.camaraBtn {
  position: absolute;
  right: 0;
  bottom: 10px;
  background-color: $local-primary;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  border: 0;
  display: grid;
  place-content: center;
  cursor: pointer;
  img {
    height: 20px;
    width: 20px;
  }
}

.after-colan {
  position: relative;
  &::after {
    content: ":";
    position: absolute;
    right: 0;
    top: 0;
    font-weight: bold;
    @include mediaResponsive(sm) {
      content: "";
    }
  }
}

.loading-div {
  display: grid;
  place-content: center;
  position: fixed;
  background-color: rgba($color: $local-black, $alpha: 0.5);
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 999999;
}
.font-normal {
  font-size: 16px !important;
}
.text-transform-none {
  text-transform: none !important;
}
.makeStyles-navLink-267 {
  @include mediaResponsive(lg) {
    margin-left: 0 !important;
  }
}

.full-loader {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: $local-white;
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%);
    max-width: 100%;
    max-height: 100%;
    background-repeat: no-repeat;
  }
}

// [class^=makeStyles-imgSection],
// [class^=makeStyles-smallImgSection-],
// [class^=makeStyles-bigImgSection-]{
//   overflow: hidden !important;
//   position: relative;
//   img{
//     transition: all .5s ease;
//     border-radius: 5px !important;
//   }
//   &:hover{
//     img{
//       transform: scale(1.05);
//       border-radius: 5px !important;
//     }
//   }
// }

.auth-page {
  footer {
    a {
      padding: 0.9375rem !important;
      font-size: 14px !important;
      text-transform: capitalize !important;
      font-weight: 400 !important;
    }
  }
}

.header-logo-link {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $local-borderGrey;
  @include mediaResponsive(sm) {
    padding-left: 0 !important;
  }
  img {
    margin-right: 5px;
  }
}

a.text-white {
  color: $local-white !important;
}

.pagination-container {
  display: flex;
  justify-content: center;
}

.pagination {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  outline: none;
}
.pagination > li {
  margin-right: 10px;
}

.pagination > .active > a {
  background-color: #0a435b;
  border-radius: 50%;
  color: #fff;
  border: none;
}
.pagination > li > a {
  border: none;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
  border-radius: 50%;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #0a435b;
  border-color: #0a435b;
  outline: none;
}
.pagination > li > a,
.pagination > li > span {
  color: #0a435b;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
  margin: 0 10px;
  color: black;
}

.pagination-container {
  .pagination {
    .disabled {
      a {
        color: gray;
        cursor: auto;
      }
    }
  }
}

.noUi-connect {
  background-color: #0a435b !important;
}
.noUi-handle {
  border: 1px solid #bfcfd5 !important ;
}

// regular font for all site

.textregular {
  font-size: 16px;
  font-weight: 400;
  color: #000 !important;
}
.sortByLabel {
  font-weight: 600;
  font-size: 14px;
  color: #000;
  padding-right: 16px;
}

// custom event card

.CustomEventCard {
  background-color: #fff;
  border-radius: 7px;
  transition: all 0.2s ease-in-out;

  &:hover {
    // box-shadow:
    //   0 4px 18px 0px rgb(0 0 0 / 12%), 0 7px 10px -5px rgb(0 0 0 / 15%);
    transform: scale(1.01);
  }
  .customCardBody {
    padding: 0 7px;
    padding-bottom: 12px;
  }
}

.ck-content {
  min-height: 120px;
}
.lightGrayTopBorder {
  border-top: 1px solid #e1e1e1 !important;
}

.lightGrayBottomBorder {
  border-bottom: 1px solid #e1e1e1 !important;
}

.border0 {
  border: none !important;
}

.breakWord {
  word-wrap: break-word;
}

.createEventUploadContainer {
  .MuiIconButton-colorSecondary:hover {
    background-color: white !important;
  }
  .MuiCheckbox-colorSecondary {
    &.Mui-checked {
      background-color: #7db6a6 !important;
      color: white !important;
    }
  }
}

.selectRecurrencePatternRadio {
  .MuiRadio-colorSecondary:hover {
    background-color: white;
  }
  .MuiRadio-colorSecondary {
    &.Mui-checked {
      color: #7db6a6;
    }
  }
}

.HeaderContainer {
  .MuiRadio-root {
    color: $local-white !important;
  }
  .Mui-checked {
    color: $local-white !important;
  }
  span {
    color: $local-white !important;
    font-size: 14px;
    font-weight: 400;
  }
}

.bread-crumb-text {
  color: white;
  font-weight: bold;
  font-size: 42px;
}

.customLink {
  &:hover {
    color: #7db6a6 !important;
  }
  &:focus {
    color: #7db6a6 !important;
  }
}

.customTimePicker {
  .rdtBtn {
    color: #7db6a6;
  }
  .rdtCounters .rdtCounter:last-child .rdtCount {
    color: #7db6a6;
    border-color: #7db6a6;
  }
  .form-control {
    background-image: linear-gradient(#7db6a6, #7db6a6),
      linear-gradient(#d2d2d2, #d2d2d2) !important;

    &:focus {
      background-image: linear-gradient(#7db6a6, #7db6a6),
        linear-gradient(#d2d2d2, #d2d2d2) !important;
      background-size: 100% 1px, 100% 1px !important;
    }
  }
}

.eventTimePicker {
  input.form-control {
    height: 26px !important;
  }
}

.signup-title-text {
  font-size: 32px;
  text-align: center;
}
.signup-radioLable {
  font-size: 14px;
}

.reserved-white {
  color: $local-white !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}
.reserved-black {
  color: $local-black !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.table-loading {
  width: 100%;
  padding: 20px;
  display: grid;
  place-content: center;
}

.titleHeading-text {
  color: #0a435b;
  font-size: 32px;
  font-weight: 800;
}

.fs-14 {
  font-size: 14px !important;
}

.form-group {
  .form-control-placeholder {
    color: $local-black !important;
  }
}

.modalHeader-border {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    border-bottom: 1px solid #e1e1e1;
    width: 100%;
  }
}
.autoLocation {
  position: absolute;
  top: 25px;
  height: fit-content;
  width: calc(100% - 30px);
  background: whitesmoke;
  z-index: 99;
  font-size: 13px;
  font-weight: 500;
  padding: 5px 0px;
  max-height: 250px;
  overflow-y: auto;
  .autoLocationLoading {
    display: flex;
    justify-content: center;
    overflow: hidden;
    cursor: auto;
    &:hover {
      border-color: transparent;
      background-color: transparent;
    }
  }
  .autoLocationNoResult {
    display: flex;
    justify-content: center;
    cursor: auto;
    &:hover {
      border-color: transparent;
      background-color: transparent;
    }
  }
  div {
    padding: 2px 5px;
    cursor: pointer;
    border-left: 4px solid transparent;
    transition: all 0.3s ease;
    &:hover {
      border-color: #02425a;
      background-color: #02425a22;
    }
  }
}

.eventFilterExpand {
  &:hover {
    cursor: pointer;
  }
}
.eventFilterHide {
  display: none;
}
.eventFilterShow {
  display: block;
}

.cursor-pointer {
  cursor: pointer;
}

.green-icon {
  color: #7db6a6;
}

.icon-size {
  font-size: 20px;
}

.noUi-horizontal {
  margin: 0;
}

.eventDateIcon {
  position: relative;
  &::after {
    content: "\e910";
    font-family: "icomoon" !important;
    position: absolute;
    right: 8px;
    top: 5px;
    font-size: 18px;
    color: $local-black;
  }
}
.custom-noui-slider {
  width: 100%;
  .noUi-tooltip {
    font-size: 10px;
    font-weight: 500;
    padding: 3px 10px;
    color: $local-white;
    background-color: #02425a;
    border: none;
  }
  .noUi-tooltip {
    bottom: 200%;
  }
  .noUi-connects {
    background-color: #e5ecee;
  }
  .noUi-handle {
    // position: relative;
    &::before {
      content: "";
      position: absolute;
      top: -175%;
      height: 12px;
      width: 12px;
      transform: rotate(45deg);
      background-color: #02425a;
    }
  }
}
.slider-range-left {
  font-size: 11px;
  margin-right: 5px;
  color: $local-black;
  font-weight: 400;
}
.slider-range-right {
  font-size: 11px;
  margin-left: 5px;
  color: $local-black;
  font-weight: 400;
}
amplify-s3-image.s3ImageRound::part(img) {
  border-radius: 50% !important;
}

amplify-s3-image.s3EventThumbnail::part(img) {
  object-fit: cover;
}

amplify-s3-image.s3SliderImage::part(img) {
  border-radius: 7px;
  object-fit: cover;
}

.create-event-custom-datPicker {
  .form-control {
    background-image: linear-gradient(#7db6a6, #7db6a6),
      linear-gradient(#d2d2d2, #d2d2d2) !important;

    &:focus {
      background-image: linear-gradient(#7db6a6, #7db6a6),
        linear-gradient(#d2d2d2, #d2d2d2) !important;
      background-size: 100% 1px, 100% 1px !important;
    }
  }

  .rdtPicker {
    bottom: 120% !important;
    top: auto !important;

    &::after {
      display: none;
    }
    &::before {
      display: none;
    }
  }
}

.sm-device-heading {
  @include mediaResponsive(sm) {
    font-size: 2rem !important;
  }
}

.select-required {
  .select__placeholder {
    &::after {
      content: "*";
      position: absolute;
      right: -5px;
      top: -5px;
      width: fit-content;
      color: #0a435b;
      font-size: 14px;
    }
  }
}

.css-26l3qy-menu {
  z-index: 2 !important;
}

.event-details-actions {
  background-color: #7db6a6;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 50%;

  svg {
    color: white;
  }
}

.page-title {
  margin-bottom: 0;
  color: #0a435b;
  font-weight: 800;
  font-size: 32px;
}
// .auth-page{
//   background-image: url('./src/assets/img/bg7.png');
// }

.table-header-bg {
  .rdt_TableHeadRow {
    background-color: #edf2f3;
    border-bottom: none;
    border-radius: 5px;
  }
}

.padding-top-xs {
  @media (max-width: 320px) {
    padding-top: 100px;
  }
}

.specificDateContainer {
  max-height: 250px;
  overflow-y: auto;
}

.MuiRadio-colorSecondary.Mui-checked {
  color: #02425a !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #02425a !important;
}

.thankYou-greeting-modal {
  background-color: $local-white;
  border-radius: 7px;
  width: 30%;

  @include mediaResponsive(lg) {
    width: 60%;
  }
  @include mediaResponsive(mLg) {
    width: 50%;
  }
  @include mediaResponsive(md) {
    width: 95%;
  }

  .greeting-img {
    @include mediaResponsive(sm) {
      height: 100px;
    }
  }
}

.custom-input {
  padding-top: 0 !important;
}

.tagStyle {
  @include mediaResponsive(mLg) {
    margin-bottom: 8px;
  }
  @include mediaResponsive(sm) {
    margin-bottom: 8px;
  }
}

.btn-shadow-none {
  box-shadow: none !important;
}
.p-0 {
  padding: 0 !important;
}

.text-status-complete {
  color: #999;
}

.text-status-scheduled {
  color: $local-lightGreen;
}

.text-status-cancelled {
  color: #ff0000;
}

.payment-logo {
  position: relative;
  &::before {
    content: "$";
    position: absolute;
    font-weight: 500;
    color: black;
    left: 16px;
    top: 1px;
  }
  &::after {
    content: "USD";
    position: absolute;
    font-weight: 500;
    color: black;
    left: 10px;
    top: 21px;
    font-size: 12px;
  }
}

.rounded-sm-img {
  height: 110px;
  width: 110px;
  border-radius: 50%;
}

.e-schedule-dialog {
  min-height: auto !important;
}

.recurringText {
  color: #7db6a6;
  font-weight: 400;
}

.oneTimeText {
  color: #02425b;
  font-weight: 400;
}

.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after {
  background: #7db6a6 !important;
  height: 1px !important;
}

.e-calendar .e-content td.e-focused-date.e-today span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today span.e-day,
.e-calendar .e-content td.e-today span.e-day {
  border: 1px solid #7db6a6 !important;
  color: #7db6a6 !important;
}
.e-calendar .e-content td.e-selected.e-focused-date span.e-day {
  background-color: #02425a !important;
  color: #fff !important;
}
.e-btn.e-flat.e-primary,
.e-css.e-btn.e-flat.e-primary,
.e-datetime-wrapper.e-input-group:not(.e-disabled)
  .e-input-group-icon.e-active:active,
.e-datetime-wrapper .e-input-group-icon.e-icons.e-active,
.e-date-wrapper span.e-input-group-icon.e-date-icon.e-icons.e-active,
.e-datetimepicker.e-popup .e-list-parent.e-ul .e-list-item.e-active {
  color: #0a435b !important;
}

.filterContainer .e-input,
.filterContainer ::placeholder {
  font-size: 16px !important;
  color: white !important;
}

.e-input-group:not(.e-float-icon-left),
.e-input-group.e-success:not(.e-float-icon-left),
.e-input-group.e-warning:not(.e-float-icon-left),
.e-input-group.e-error:not(.e-float-icon-left),
.e-input-group.e-control-wrapper:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-success:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-warning:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left) {
  border-color: #277578 !important;
}

.filterContainer .e-date-icon::before,
.filterContainer .e-clear-icon::before,
.filterContainer .e-range-icon::before {
  color: #fff;
}

.filterContainer .e-input-group,
.e-input-group.e-control-wrapper {
  margin-bottom: 0px !important;
}

.e-schedule .e-vertical-view .e-header-cells.e-current-day {
  color: #02425a !important;
}

.schedule-control-section {
  .control-wrapper {
    .e-active-view {
      .e-tbar-btn-text {
        color: #02425a !important;
      }
    }

    .e-vertical-view {
      .e-current-time {
        color: #02425a !important;
      }
      .e-current-timeline,
      .e-previous-timeline {
        border-top-color: #02425a !important;
      }
    }
    .e-month-view {
      td.e-current-day {
        color: #02425a !important;
      }
      .e-current-date .e-date-header {
        background-color: #02425a !important;
      }
    }
    .e-agenda-view .e-day-date-header.e-current-day {
      color: #02425a !important;
    }
    .e-spinner-pane .e-spinner-inner .e-spin-material {
      stroke: #02425a !important;
    }
  }
}

.donation-title {
  font-size: 20px;
}

.cancel-icon {
  font-size: 100px;
  color: #f27474;
}

.statusText {
  font-weight: 500;
}

.receivedStatus {
  color: #7db6a6;
}

.failedStatus {
  color: #f27474;
}

.pendingStatus {
  color: #a4846b;
}
